<template>
    <div class="users-autocomplete">
        <div class="form-group">
            <v-combobox
                    :value="getSelectedUser"
                    :items="items"
                    item-text="name"
                    item-value="id"
                    outlined
                    clearable
                    prepend-inner-icon="search"
                    @input="onInput"
                    :placeholder="$t('message.search') + '...'"
            ></v-combobox>
            <label class="label">
                {{$t('message.teachersAndStudents')}}
                <i class="warning-icon">!</i>
            </label>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsersAutocomplete',
  data: () => ({}),
  computed: {
    ...mapGetters({
      teachers: 'teachers/getTeachers',
      students: 'students/getStudents',
      getSelectedUser: 'individualPlan/getSelectedUser',
    }),
    items() {
      let studentsArr = [];
      this.students.forEach((item) => {
        studentsArr = studentsArr.concat(item.group_students);
      });
      const items = this.teachers.concat(studentsArr);
      if (this._$can('r,rs', 'individual_plan')) {
        return items;
      }
      return items.filter(item => item.id === this._getUser.id);
    },
  },
  methods: {
    onInput(user) {
      this.$emit('onInput', user);
    },
  },
};
</script>

<style lang="scss">
    .users-autocomplete{
        .v-text-field.v-text-field--enclosed .v-input__prepend-inner{
            margin-top: 9px;
            margin-left: 10px
        }
        .form-group {
            padding-top: 30px;
            position: relative;
            max-width: 100%;
            .v-input input{
                max-height: 40px;
            }
            .label{
                position: absolute;
                left: 0;
                top: 0;
                font-size: 12px;
                color: $defaultColor;
                margin-bottom: 8px;
                display: block;
                .warning-icon {
                    opacity: 0;
                }
            }
            .error--text + .label, .error--text + .v-menu + .label{
                .warning-icon {
                    opacity: 1;
                }
            }
        }
    }
</style>
