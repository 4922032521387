<template>
    <div class="wrapper">
        <CommonContent>
            <template #sidebar>
                <calendar-picker
                        @dayClick="changePeriod"
                        :currentDate="getCurrentDate"
                        :mode="getPickerMode"/>
                <div>
                    <UsersAutocomplete
                        v-if="_$can('r,rs', 'individual_plan')"
                        @onInput="setUser"
                    />
                </div>
            </template>
            <template #content>
                <Calendar
                        @getData="getData"
                        @prevPeriod="changePeriod"
                        @nextPeriod="changePeriod"
                        @changeEvent="editEvent"
                        @createEvent="createEvent"
                        disableEvents
                        :disableCreate="!_$can('c', 'event') && !_$can('c', 'event_reservation')"
                        :disableUpdate="!_$can('u', 'event')"
                        :showPeriod="true"
                        :showEventRoom="true"
                        :currentDate="getCurrentDate"
                        :roomsWithEvents="events"
                        :view="getCalendarType"
                        :isLoading="isAppLoading"
                />
            </template>
        </CommonContent>
        <event-modal
                v-model="modal.show"
                :date="modal.date"
                @close="hideModal"
                @updateData="getData"
        />
    </div>
</template>

<script>
import CommonContent from '@components/CommonContent/CommonContent.vue';
import CalendarPicker from '@components/CalendarPicker/CalendarPicker.vue';
import UsersAutocomplete from '@components/UsersAutocomplete/UsersAutocomplete.vue';
import { mapGetters } from 'vuex';
import Calendar from '@components/Calendar/Calendar.vue';
import constants from '@/constants';
import EventModal from '@/components/Modals/EventModal.vue';

export default {
  name: 'IndividualPlan',
  components: {
    CommonContent,
    CalendarPicker,
    UsersAutocomplete,
    Calendar,
    EventModal,
  },
  data: () => ({
    modal: {
      show: false,
      date: '',
    },
    editableEvent: {},
  }),
  created() {
    if (!this._$can('r,rs', 'individual_plan') && this._$can('rs', 'individual_plan')) {
      this.setUser(this._getUser);
    }
  },
  methods: {
    getData() {
      this.$api.individualPlan.getSchedule(
        this.getCurrentDate.format(constants.backendDateFormat),
        this.getSelectedUser,
      );
      this.hideModal();
    },
    changePeriod(date) {
      this.$store.dispatch('individualPlan/setCurrentDate', date);
    },
    hideModal() {
      this.modal.show = false;
      this.modal.date = '';
    },
    setUser(user) {
      this.$store.dispatch('individualPlan/setSelectedUser', user);
      this.getData();
    },
    createEvent(date) {
      this.modal.show = true;
      this.modal.date = date;
    },
    editEvent(id) {
      this.$api.events.getById(id).then((data) => {
        this._setEvent(data);
        this.modal.show = true;
      });
    },
  },
  computed: {
    ...mapGetters({
      events: 'individualPlan/getListEvents',
      getCurrentDate: 'individualPlan/getCurrentDate',
      getSelectedUser: 'individualPlan/getSelectedUser',
    }),
    getCurrentDate() {
      return this.$store.getters['individualPlan/getCurrentDate'];
    },
    getPickerMode() {
      return constants.calendarPickerMods.range;
    },
    getCalendarType() {
      return constants.calendarViews.week;
    },
  },
};
</script>
